import { SET_OPEN, SET_CLOSE } from "./types.js";

export const openModal = () => (dispatch) => {
  dispatch({
    type: SET_OPEN
  });
};


export const closeModal = () => (dispatch) => {
    dispatch({
      type: SET_CLOSE
    });
  };