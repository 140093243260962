import  axios from "../axiosConfig";

const refrescarLogin = (store) => (next) => (action) => {
  let margenFecha = 180;
  let estado = store.getState();

  if (
    estado.auth.token_info !== null &&
    estado.auth.isAuthenticated !== null &&
    estado.auth.user !== null
  ) {
    let refresh = localStorage.getItem("refresh");
    let fechaCaducidad = estado.auth.token_info.exp;//cantidad de segundos desde epoc
    let fechaActual = Date.now()/1000; //lo pasamos a segundos para comparar en la misma unidad
    if (fechaActual + margenFecha > fechaCaducidad) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ refresh });

      axios
        .post("/token/refresh/", body, config)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
        });
    }
  }

  let result = next(action);

  return result;
};

export default refrescarLogin;
