import axios from "../axiosConfig";

import {
  FETCH_TIRAS,
  GET_TIRAS,
  FETCH_TIRAS_DET,
  GET_TIRAS_DET,
} from "./types";
import { tokenConfig } from "./auth";

// GET TIRAS
export const getTiras = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIRAS,
  });
  axios
    .get(`/tiras`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIRAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getTirasDet = (id_tira) => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIRAS_DET,
  });

  axios
    .get(`/tirasdet?tira=${id_tira}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIRAS_DET,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
