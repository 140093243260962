import config from "./config";
import axios from "axios";

const axiosConfig = axios.create({
  baseURL: config.backend.url,
});

axiosConfig.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (config.log) {
      console.log(response);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (config.log) {
      console.log(error.response);
    }
    if (error.response.status === 401) {
      window.location.reload("/");
    }
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.request.use(
  function (config) {
    if (config.log) {
      console.log(config);
    }
    return config;
  },
  function (error) {
    if (config.log) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
