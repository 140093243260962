import {
    FETCH_OFERTAS_MULTIPLES,
    GET_OFERTAS_MULTIPLES
  } from "../actions/types.js";
  
  const initialState = {
    isLoading: false,
    ofertas_multiples: {},
    
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {
    
        case FETCH_OFERTAS_MULTIPLES:
      
        return {
          ...state,
          ofertas_multiples: {},
          isLoading: true,
        };
        case GET_OFERTAS_MULTIPLES:
   
          return {
            ...state,
            isLoading: false,
            ofertas_multiples: action.payload,
          };
        
      default:
        return state;
    }
  }
  