import {
  FETCH_TRANSPORTISTAS,
  GET_TRANSPORTISTAS,
  SET_TRANSPORTISTA,
} from "../actions/types.js";

const initialState = {
  transportistas: [],
  transportista: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSPORTISTAS:
      return {
        ...state,
        transportistas: [],
        transportista: {},
        isLoading: true,
      };
    case GET_TRANSPORTISTAS:
      return {
        ...state,
        transportistas: action.payload,
        isLoading: false,
      };
    case SET_TRANSPORTISTA:
      return {
        ...state,
        transportista: action.payload,
      };
    default:
      return state;
  }
}
