import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showLoader, hideLoader } from "../../actions/loader";

export class Loader extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    authIsLoading: PropTypes.bool.isRequired,
    articulosIsLoading: PropTypes.bool.isRequired,
    portalIsLoading: PropTypes.bool.isRequired,
    menusIsLoading: PropTypes.bool.isRequired,
    listasIsLoading: PropTypes.bool.isRequired,
    pedidoIsLoading: PropTypes.bool.isRequired,
  };

  componentDidUpdate() {
    const {
      isLoading,
      authIsLoading,
      articulosIsLoading,
      portalIsLoading,
      menusIsLoading,
      listasIsLoading,
      pedidoIsLoading,
      pedidosAnterioresIsLoading,
      contactoIsLoading,
      widgetsIsLoading,
      expedientesIsLoading,
      modularIsLoading,
    } = this.props;
    if (
      !isLoading &&
      !authIsLoading &&
      !articulosIsLoading &&
      !portalIsLoading &&
      !menusIsLoading &&
      !listasIsLoading &&
      !pedidoIsLoading &&
      !pedidosAnterioresIsLoading &&
      !contactoIsLoading &&
      !widgetsIsLoading &&
      !expedientesIsLoading &&
      !modularIsLoading
    ) {
      this.props.hideLoader();
    } else {
      this.props.showLoader();
    }
  }

  render() {
    return (
      <div id="loader-wrapper">
        <div id="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.informes.isLoading,
  authIsLoading: state.auth.isLoading,
  articulosIsLoading: state.listadoArticulos.isLoading,
  portalIsLoading: state.portal.isLoadingPortal,
  menusIsLoading: state.portal.isLoadingMenus,
  listasIsLoading: state.listasCompra.isLoading,
  pedidoIsLoading: state.pedidos.isLoading,
  pedidosAnterioresIsLoading: state.pedidosAnteriores.isLoading,
  contactoIsLoading: state.contacto.isLoading,
  widgetsIsLoading: state.widgets.isLoading,
  expedientesIsLoading: state.expedientes.isLoading,
  modularIsLoading: state.modular.cargando,
});

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
})(Loader);
