import {
  FETCH_MENU_FAMILIAS,
  GET_MENU_FAMILIAS,
  FETCH_IMAGENES_FAMILIAS,
  GET_IMAGENES_FAMILIAS,
  SET_FAMILIA_SELECCIONADA,
  UNSET_FAMILIA_SELECCIONADA,
} from "../actions/types.js";

const initialState = {
  familias: [],
  imagenes_familias: [],
  familia_seleccionada: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MENU_FAMILIAS:
      return {
        ...state,
        familias: [],
        isLoading: true,
      };
    case GET_MENU_FAMILIAS:
      return {
        ...state,
        isLoading: false,
        familias: action.payload["arbolFamilias"],
      };
    case FETCH_IMAGENES_FAMILIAS:
      return {
        ...state,
        imagenes_familias: [],
        isLoading: true,
      };
    case GET_IMAGENES_FAMILIAS:
      return {
        ...state,
        isLoading: false,
        imagenes_familias: action.payload,
      };
    case SET_FAMILIA_SELECCIONADA:
      return {
        ...state,
        familia_seleccionada: action.payload,
      };
    case UNSET_FAMILIA_SELECCIONADA:
      return {
        ...state,
        familia_seleccionada: {},
      };
    default:
      return state;
  }
}
