import {
  GET_DESCRIPCIONES,GET_DESCRIPCIONES_SUCCESS, GET_DESCRIPCIONES_FAIL 
} from "../actions/types.js";

const initialState = {
  descripciones: {},
};

export default function reducer(state = initialState, action) {
  let lista_descripciones;
  switch (action.type) {
    case GET_DESCRIPCIONES:
      lista_descripciones = state.descripciones;
      
      lista_descripciones[action.codigo_articulo] = {
        data: {},
        isLoading: true,
        error: false,
      };
      return {
        ...state,
        descripciones: { ...state.descripciones, ...lista_descripciones },
      };
    case GET_DESCRIPCIONES_SUCCESS:
      lista_descripciones = state.descripciones;
      
      lista_descripciones[action.codigo_articulo].data = action.payload;
      lista_descripciones[action.codigo_articulo].isLoading = false;
      lista_descripciones[action.codigo_articulo].error = false;
      return {
        ...state,
        descripciones: { ...state.descripciones, lista_descripciones },
      };
    case GET_DESCRIPCIONES_FAIL:
      lista_descripciones = state.precio;
      
      lista_descripciones[action.codigo_articulo].data = {};
      lista_descripciones[action.codigo_articulo].isLoading = false;
      lista_descripciones[action.codigo_articulo].error = true;
      return {
        ...state,
        descripciones: { ...state.descripciones, lista_descripciones },
      };
    default:
      return state;
  }
}
