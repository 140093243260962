import {
  GET_ORGANIZACIONES_COMPRAS,
  GET_ORGANIZACIONES_COMPRAS_SUCCESS,
  GET_ORGANIZACIONES_COMPRAS_FAILURE,
} from "../actions/types.js";

const initialState = {
  organizaciones_compras: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZACIONES_COMPRAS:
      return {
        ...state,
        organizaciones_compras: [],
        isLoading: true,
      };
    case GET_ORGANIZACIONES_COMPRAS_SUCCESS:
      return {
        ...state,
        organizaciones_compras: action.payload,
        isLoading: false,
      };
    case GET_ORGANIZACIONES_COMPRAS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
