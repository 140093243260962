import {
  FETCH_TIPOS_PEDIDO,
  GET_TIPOS_PEDIDO,
  SET_TIPO_PEDIDO,
} from "../actions/types.js";

const initialState = {
  tipos_pedido: [],
  tipo_pedido: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIPOS_PEDIDO:
      return {
        ...state,
        tipos_pedido: [],
        tipo_pedido: {},
        isLoading: true,
      };
    case GET_TIPOS_PEDIDO:
      return {
        ...state,
        tipos_pedido: action.payload,
        isLoading: false,
      };
    case SET_TIPO_PEDIDO:
      return {
        ...state,
        tipo_pedido: action.payload,
      };
    default:
      return state;
  }
}
