import {
  ENVIAR_REGISTRO_HIJO,
  ENVIAR_REGISTRO_HIJO_SUCCESS,
  ENVIAR_REGISTRO_HIJO_FAIL,
  FETCH_USUARIOS_HIJOS,
  GET_USUARIOS_HIJOS,
  GET_USUARIOS_HIJOS_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  hijos: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENVIAR_REGISTRO_HIJO:
    case FETCH_USUARIOS_HIJOS:
      return {
        ...state,
        isLoading: true,
      };

    case ENVIAR_REGISTRO_HIJO_SUCCESS:
      if (action.es_edicion === "N") {
        return {
          ...state,
          isLoading: false,
          hijos: [...state.hijos, action.payload],
        };
      } else {
        let nuevos_hijos = state.hijos.filter(
          (elem) => elem.usuario_web !== action.payload.usuario_web
        );

        nuevos_hijos.push(action.payload);

        return {
          ...state,
          isLoading: false,
          hijos: nuevos_hijos,
        };
      }
    case ENVIAR_REGISTRO_HIJO_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USUARIOS_HIJOS:
      return {
        ...state,
        hijos: action.payload,
      };
    case GET_USUARIOS_HIJOS_FAIL:
      return {
        ...state,
        isLoading: false,
        hijos: [],
      };
    default:
      return state;
  }
}
