import {
  GET_EFACTURAS_VENTAS,
  GET_EFACTURAS_VENTAS_SUCCESS,
  GET_EFACTURAS_VENTAS_FAILURE,
  SUBIR_EFACTURA_VENTAS,
  SUBIR_EFACTURA_VENTAS_SUCCESS,
  SUBIR_EFACTURA_VENTAS_FAILURE,
  GET_MAS_EFACTURAS_VENTAS,
  GET_MAS_EFACTURAS_VENTAS_SUCCESS,
  GET_MAS_EFACTURAS_VENTAS_FAILURE,
} from "../actions/types.js";

const initialState = {
  efacturas_ventas: [],
  next: "",
  previous: "",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EFACTURAS_VENTAS:
      return {
        ...state,
        efacturas_ventas: [],
        isLoading: true,
      };

    case GET_EFACTURAS_VENTAS_SUCCESS:
      return {
        ...state,
        efacturas_ventas: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        isLoading: false,
      };
    case GET_EFACTURAS_VENTAS_FAILURE:
    case SUBIR_EFACTURA_VENTAS_FAILURE:
    case GET_MAS_EFACTURAS_VENTAS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_MAS_EFACTURAS_VENTAS:
    case SUBIR_EFACTURA_VENTAS:
      return {
        ...state,
        isLoading: true,
      };
    case SUBIR_EFACTURA_VENTAS_SUCCESS:
      return {
        ...state,
        efacturas_ventas: [...state.efacturas_ventas, ...action.payload],
        isLoading: false,
      };

    case GET_MAS_EFACTURAS_VENTAS_SUCCESS:
      return {
        ...state,
        efacturas_ventas: [
          ...state.efacturas_ventas,
          ...action.payload.results,
        ],
        next: action.payload.next,
        previous: action.payload.previous,
        isLoading: false,
      };

    default:
      return state;
  }
}
