import { SET_ARTICULO, SET_OPEN, SET_CLOSE,SET_LINEAS } from "../actions/types.js";

const initialState = {
  isOpen: false,
  codigo_articulo: "",
  lineas:[]
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ARTICULO:
      return {
        ...state,
        codigo_articulo: action.payload,
        lineas:[],
        isOpen: true,
      };
      case SET_LINEAS:
        return {
          ...state,
          lineas: action.payload,
          codigo_articulo:"",
          isOpen: true,
        };
    case SET_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case SET_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
